<template>
    <div style="width: 100%" v-loading.fullscreen.loc="loading" :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="clearfix"></div>
        <el-row :gutter="10" class="mlr-1">
            <el-col :sm="24" :md="24">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="120px"
                    class="demo-ruleForm">
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="12" :lg="12">
                            <el-form-item label="Api Key" prop="apiKey">
                                <el-input v-model="ruleForm.apiKey"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="24" :md="12" :lg="12">
                            <el-form-item label="Reply Email" prop="replyEmail">
                                <el-input v-model="ruleForm.replyEmail"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-form-item>
          <el-checkbox v-model="ruleForm.isDefault">Make it default payment system</el-checkbox>
        </el-form-item> -->
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">Integrate</el-button>
                        <el-button @click="resetForm('ruleForm')">Reset</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Add_API_Details",
    components: {},
    props: ["type"],
    data() {
        return {
            loading: false,
            loadingText: 'Loading ......!',
            ruleForm: {
                apiKey: '',
                replyEmail: '',
                type: 'SEND_GRID_API_KEY'

            },
            rules: {
                apiKey: [{ required: true, message: 'Please enter api details', trigger: 'blur' },],
                replyEmail: [
                    { required: true, message: 'Please enter valid email', trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo"]),
        ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
        ...mapGetters("customConfiguration", ["getSmtpConfiguration", "getSmtpStatus", "getError"])
    },
    created() { },
    mounted() {
        if (this.type) {
            {
                this.ruleForm.type = this.type;
            }
        }
    },
    methods: {
        validateEmail(email) {
            // Regular expression pattern for basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        async getSMTPDetails() {
            this.loading = true;
            this.loadingText = "Fetching SMTP Details";
            // await this.$store.dispatch("paymentGateway/fetchActivePaymentSystems");
            console.log("active systems", this.getActivePaymentSystems);
            this.loadingText = "Loading ......!";
            this.loading = false;
        },
        async submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                this.loading = true;
                this.loadingText = "Verifying your form details ....!"
                if (valid) {
                    this.loading = true;
                    this.loadingText = "Please wait ..... Email is validating....";
                    let emailValidate = this.validateEmail(this.ruleForm.replyEmail);
                    if (!emailValidate) {
                        this.loading = false;
                        this.loadingText = "Loading ......!";
                        this.$notify.error({
                            title: 'Error',
                            message: 'Invalid Email Details '
                        });
                        return;
                    }

                    this.loadingText = "Establishing secure  e-e encryption process ..... ";
                    const smtpData = this.$CryptoJS.AES.encrypt(JSON.stringify(this.ruleForm), this.getActiveWorkspace.company_id).toString();
                    await this.$store.dispatch("customConfiguration/addSMTP", { info: smtpData, type: this.ruleForm.type });
                    this.loadingText = "Please wait ..... Validating your  details.";
                    if (this.getError && this.getError.message) {
                        this.$notify({
                            title: "Error",
                            message: this.getError.message,
                            type: "error",
                        });
                        this.loading = false;

                        this.loadingText = "Loading ......!";
                    }
                    else {
                        this.$notify({
                            title: "Success",
                            message: `SMTP details integrated.`,
                            type: "success",
                        });
                        // this.$router.push({ path: "/profilesettings/paymentIntegrationList" })
                        this.ruleForm = {
                            hostName: '',
                            userName: "",
                            password: "",
                            port: 587,
                            replyEmail: '',
                            type: 'SEND_GRID_API_KEY'
                        }

                        this.$emit("success");
                        this.loading = false;
                        this.loadingText = "Loading ......!";
                    }


                } else {
                    console.log('error submit!!');
                    this.loading = false;
                    this.loadingText = "Loading ....!"
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }


    }
};
</script>

<style lang="scss" scoped>
.mlr-1 {
    margin: 0 1rem !important;
}

.clearfix {
    padding-bottom: 2rem;
}


.el-card__body {
    padding: 1rem !important;
}
</style>