<template>
    <div v-loading="loading">
        <el-row :gutter="20">
            <el-col>
                <!-- <el-collapse accordion>
                    <el-collapse-item name="1"> -->
                <!-- <h3>  </h3>
                <hr /> -->
                <!-- <template slot="title">
                            
                        </template> -->
                <div class="card mb-3 " style="width: 100%" v-loading="loading" element-loading-text="Loading..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                    v-if="addEditSMTPStatus">
                    <div class="card-header bg-light">
                        <h3 class="title fw-normal mb-0 p-2">mail Configuration Details</h3>
                    </div>
                    <div class="clearfix"></div>
                    <el-row :gutter="10">
                        <el-col :sm="24" :md="3" class="mlr-1">
                            <label for="MailType"> Mail Type </label>
                        </el-col>
                        <el-col :sm="24" :md="20" >
                            <el-radio-group v-model="mailType" size="mini" id="MailType">
                                <el-radio-button :label="type.value"  v-for="(type) of mailTypes"
                                    :key="type.value">{{type.name}}</el-radio-button>
                            </el-radio-group>
                        </el-col>
                        <el-col :sm="24" :md="24">
                            <SMTP v-if="mailType=='SMTP'" @success="closeConfig" ></SMTP>
                            <API v-if="mailType!='SMTP'" :type="mailType" @success="closeConfig"></API>
                        </el-col>

                    </el-row>
                </div>
                <el-row :gutter="10" v-else>
                    <el-col :sm="24" :md="24">

                        <span class="info-msg">
                            <el-row :gutter="10"
                                v-if="mailConfigurationDetails && mailConfigurationDetails[0] && mailConfigurationDetails[0].type">
                                <div :class="getClass(mailConfigurationDetails[0].validationStatus)"
                                    id="mailValidation">
                                    <h4 class="h4">Mail Configuration <i class="header-icon el-icon-info"></i></h4>
                                    <hr />
                                    <el-row :gutter="10">
                                        <el-col :sm="24" :md="24">
                                            <table class="table" v-if="mailConfigurationDetails[0].type=='SMTP'">
                                                <tr class="tr">
                                                    <th class="th"><span class="mlr-1"> Host </span></th>
                                                    <td class="td"><span class="mlr-1">
                                                            {{ mailConfigurationDetails[0].details.hostName }}
                                                        </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">Port </span></th>
                                                    <td class="td"><span class="mlr-1">
                                                            {{ mailConfigurationDetails[0].details.port }}
                                                        </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">User Name</span></th>
                                                    <td class="td"> <span class="mlr-1">{{
        mailConfigurationDetails[0].details.userName
    }} </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"><span class="mlr-1"> Reply To Email </span></th>
                                                    <td class="td"><span class="mlr-1">{{
            mailConfigurationDetails[0].details.replyEmail
                                                            }} </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">Status</span></th>
                                                    <td class="td"><span class="mlr-1"> {{
                                                            mailConfigurationDetails[0].validationStatus
                                                            }} </span> </td>
                                                </tr>
                                            </table>
                                            <table class="table" v-else>
                                                
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">Port </span></th>
                                                    <td class="td"><span class="mlr-1">
                                                            {{ getMailType(mailConfigurationDetails[0].type).name }}
                                                        </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">API Key</span></th>
                                                    <td class="td"> <span class="mlr-1"> XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"><span class="mlr-1"> Reply To Email </span></th>
                                                    <td class="td"><span class="mlr-1">{{
            mailConfigurationDetails[0].details.replyEmail
                                                            }} </span></td>
                                                </tr>
                                                <tr class="tr">
                                                    <th class="th"> <span class="mlr-1">Status</span></th>
                                                    <td class="td"><span class="mlr-1"> {{
                                                            mailConfigurationDetails[0].validationStatus
                                                            }} </span> </td>
                                                </tr>
                                            </table>
                                        </el-col>
                                    </el-row>

                                </div>
                            </el-row>
                            <el-row v-else>
                                <div :class="getClass('')">
                                    <h4 class="h4">Mail Configuration <i class="header-icon el-icon-info"></i></h4>
                                    <div class="alert_text">
                                        Start optimizing your email delivery now !
                                        <br /> <br />
                                        Seamlessly integrate your Trusted Mail server today for enhanced recognition and
                                        trust with
                                        industry-leading services. With top-notch security measures in place, your
                                        details are
                                        safeguarded for seamless and robust operations.
                                        <br /><br />

                                        <el-link :underline="false" icon="el-icon-connection" type="primary"
                                            @click="configMailServer">
                                            Get started now to elevate your email game!</el-link>
                                    </div>
                                </div>
                            </el-row>
                        </span>
                    </el-col>
                </el-row>

                <!-- </el-collapse-item>
                </el-collapse> -->
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SMTP from "./Mail/smtp";
import API from "./Mail/API_Key";
export default {
    name: "MailConfiguration",
    data() {
        return {
            layout: "div",
            addEditSMTPStatus: false,
            mailConfigurationDetails: {},
            loading: false,
            mailTypes: [{
                name: 'SMTP', value: 'SMTP',
            }, {
                value: 'SEND_GRID_API_KEY', name: 'Send Grid Web APi',
            }, {
                value: 'BREVO_API_KEY', name: 'Brevo Web APi',
            }],
            mailType: 'SMTP',
        };
    },
    mixins: [],
    mounted() {
        this.getSMTP();
    },
    components: {
        SMTP,API
    },
    computed: {
        ...mapGetters("auth", [
            "getUpdateProfilePicStatus",
            "getAuthenticatedUser",
            "getActiveWorkspace"
        ]),
        ...mapGetters("customConfiguration", ["getSmtpConfiguration", "getSmtpStatus", "getError"]),
        getClass() {
            return (value) => {
                // "VALID","NOT_VALID","NEED_ACTION",'UNKNOWN'
                if (value == 'UNKNOWN') {
                    return "alert alert-info";
                }
                else if (value == 'VALID') {
                    return "alert alert-success";
                }
                else if (value == 'NOT_VALID') {
                    return "alert alert-danger";
                }
                else if (value == 'NEED_ACTION') {
                    return "alert alert-warning";
                }
                else {
                    return "alert";
                }
            }
        },
        getMailType(){
            return (type)=>{
                let data=this.mailTypes.filter(obj => obj.value === type)
                    return data&& data[0] ? data[0]:{name:'',value:''};
            }
        }
    },
    methods: {
        async getSMTP() {
            this.loading = true;
            await this.$store.dispatch("customConfiguration/getSMTP");
            if (this.getSmtpConfiguration) {
                console.log(this.getSmtpConfiguration)
                // const smtpData = this.$CryptoJS.AES.encrypt(JSON.stringify(this.ruleForm), this.getActiveWorkspace.company_id).toString();
                this.mailConfigurationDetails = this.getSmtpConfiguration;

                this.mailConfigurationDetails.map((mail) => {
                    let bytes = this.$CryptoJS.AES.decrypt(mail.info, this.getActiveWorkspace.company_id);
                    mail.details = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
                    return mail
                })
                this.loading = false;


                // else if

            }
            else {
                this.loading = false;
            }
        },
        configMailServer() {
            this.loading = true;
            this.addEditSMTPStatus = !this.addEditSMTPStatus;
            this.loading = false;
        },
        async closeConfig(){
            this.loading=true;
            this.addEditSMTPStatus=false;
            await this.getSMTP();
            this.loading=false;
        }

    }
};
</script>

<style lang="scss" scoped>
.mlr-1 {
    margin: 0 1rem !important;
}
.clearfix {
    padding-bottom: 2rem;
}
.table,
.td,
.th {
    border: 1px solid #0000001c;

}

.table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 0.2em;
}

.th,
.td {
    text-align: left;
    padding: 0.4em 0em;
    //text-align: center;
}

.mlr-1 {
    margin: 0px 0.5rem;
}

.info-msg {

    .alert {
        background: #d3d3d396;
        padding: .5em 1em 2em 2em;
        margin: 0 0 0 0;
        box-shadow: inset 6px 0 rgb(67 64 64 / 38%);
        color: #000;

    }

    .alert-danger {
        background: #FAE6E6;
        box-shadow: inset 6px 0 #CC0000;
        color: #000;
        font-size: larger;
    }

    .alert-success {
        background: #98d298;
        box-shadow: inset 6px 0 #084c00;
        color: #000;
        font-size: larger;
    }

    .alert-warning {
        background: #dac586;
        box-shadow: inset 6px 0 #d39f02;
        color: #000;
        font-size: larger;
    }

    .alert-info {
        background: #d3d3d396;
        box-shadow: inset 6px 0 rgb(67 64 64 / 38%);
        color: #000;
        font-size: larger;
    }

    .h4 {
        font-size: 1.5em;
        font-weight: 700;
        color: black;
        letter-spacing: 0.02em;
        margin-top: 1em;
        margin-left: 2em;
        margin-bottom: .5em;
    }

    .alert_text {
        margin-left: 3em;
        margin-bottom: .5em;
        text-align: justify;
    }
}

/** .table{
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 0.2em;
    border-collapse: collapse;
    .tr{
        border-bottom: 1px solid lightgrey;
    }
} */
</style>